// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

type DataProps = {
  site: {
    buildTime: string
  }
}

const UsingTypescript: React.FC<PageProps<DataProps>> = ({
  data,
  path,
  location,
}) => (
  <Layout location={location}>
    <SEO title="Puente" />
    <img style={{width: '100vw'}} src="https://media.giphy.com/media/l3V0doGbp2EDaLHJC/giphy.gif" alt="" />
    <img style={{width: '100vw'}} src="https://media.giphy.com/media/l3V0doGbp2EDaLHJC/giphy.gif" alt="" />
    <img style={{width: '100vw'}} src="https://media.giphy.com/media/l3V0doGbp2EDaLHJC/giphy.gif" alt="" />
    <img style={{width: '100vw'}} src="https://media.giphy.com/media/l3V0doGbp2EDaLHJC/giphy.gif" alt="" />
  </Layout>
)

export default UsingTypescript

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
  }
`
